import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import DynamicAccordionItem from './DynamicAccordionItem';
import { getVisibilityFromRequirement } from '../useVisibilityRequirement';

const DynamicAccordion = ({ blok, ...rest }) => {
  const { items, expanded: expandedByDefault } = blok;
  const visibleItems = items.filter(
    ({
      requiredPropertyBase,
      requiredPropertyType,
      requiredPropertyFindKey,
      requiredPropertyFindValue,
    }) => {
      const { meetsRequirement } = getVisibilityFromRequirement(
        requiredPropertyBase,
        blok,
        requiredPropertyType,
        requiredPropertyFindKey,
        requiredPropertyFindValue
      );
      return meetsRequirement;
    }
  );

  const openByDefaultItems = visibleItems
    .filter((item) => item.openByDefault)
    .map((item) => item._uid);

  const initialExpandedItems = expandedByDefault
    ? [visibleItems[0]._uid]
    : openByDefaultItems;

  const [expanded, setExpanded] = useState(initialExpandedItems);

  // For the Storyblok editor
  useEffect(() => {
    if (expandedByDefault) {
      setExpanded(initialExpandedItems);
    }
  }, [expandedByDefault]);

  const onExpand = (uid) =>
    expanded.includes(uid)
      ? setExpanded(expanded.filter((id) => id !== uid))
      : setExpanded([...expanded, uid]);

  return (
    <Box width="100%">
      {visibleItems.map((item) => (
        <DynamicAccordionItem
          key={item._uid}
          blok={{ ...item, ...rest }}
          expanded={expanded}
          onExpand={onExpand}
        />
      ))}
    </Box>
  );
};

DynamicAccordion.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicAccordion;
